import React from 'react';

import { SignIn, SignInParameters } from './signin';
import { SignInWithEmail, SignInWithEmailParameters } from './signinwithemail';
import { Navbar, NavbarParameters } from './navbar';
import { Alert, AlertParameters } from './alert';

// Import Firebase dependencies.
import firebase from 'firebase/app';
import 'firebase/auth';
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import { UserCredential, FirebaseAuth } from '@firebase/auth-types';

// Import GCIP/IAP module.
import * as ciap from 'gcip-iap';

interface AppState {
  mode?: 'SIGN_IN' | 'SIGN_IN_WITH_EMAIL' | 'NONE';
  navbar: NavbarParameters;
  alertParams?: AlertParameters;
  signIn?: SignInParameters;
  signInWithEmail?: SignInWithEmailParameters;
}

class App extends React.Component<{}, AppState> implements ciap.AuthenticationHandler {
  private progressBarTimer: any;
  private ciapInstance: any;
  private config: any;
  constructor(props: {}) {
    super(props);
    this.progressBarTimer = null;
    this.state = {
      navbar: {
        link: `/${window.location.search}`,
        originalUrl: 'N/A',
      },
    };
  }

  componentDidMount() {
    // Fetch configuration via reserved Firebase Hosting URL.
    fetch('/__/firebase/init.json').then((response) => {
      return response.json();
    })
    .then((config: any) => {
      this.config = config;
      this.ciapInstance = new ciap.Authentication(this);
      const p = this.ciapInstance.start();
      this.ciapInstance.getOriginalURL().then((originalUrl: string | null) => {
        this.setState({
          navbar: {
            link:  `/${window.location.search}`,
            originalUrl: originalUrl || 'N/A',
          },
        })
      })
      .catch(() => {
        // Suppress getOriginalURL() errors as this currently only works for multi-tenant
        // use case only.
        this.setState({
          navbar: {
            link: `/${window.location.search}`,
            originalUrl:  'N/A',
          },
        })
      });
      return p;
    })
    .catch((error: any) => {
      this.updateError(error);
    });
  }

  render(): JSX.Element {
    const navbar = this.state.navbar || {};
    const alertParams = this.state.alertParams || {};
    return (
      <div className="main-container">
        <Navbar link={navbar.link} originalUrl={navbar.originalUrl} />
        <div id="sign-in-ui-container">
          {this.renderCiapComponent()}
          <Alert code={alertParams.code} message={alertParams.message} retry={alertParams.retry} />
        </div>
      </div>
    );
  }

  public getAuth(apiKey: string, tenantId: string | null): FirebaseAuth {
    let auth = null;
    
    if (apiKey !== this.config.apiKey) {
      throw new Error('Invalid project!');
    }
    try {
      auth = firebase.app(tenantId || undefined).auth();
      // Tenant ID should be already set on initialization below.
    } catch (e) {
      const app = firebase.initializeApp(this.config, tenantId || '[DEFAULT]');
      auth = app.auth();
      auth.tenantId = tenantId || null;
    }
    return auth as any;
  }

  public handleError(error: any) {
    this.updateError(error);
  }
  public startSignIn(auth: FirebaseAuth): Promise<UserCredential> {
    return new Promise((resolve, reject) => {
      this.signIn(
          !!auth.tenantId,
          (email: string) => {
            this.updateError(null);
          
            auth.fetchSignInMethodsForEmail(email)  // Here We are validating the user email is exist or not in firebase database.
              .then((signInMethods: string[]) => {
                if (signInMethods.length) {
                  // Show password sign in.
                  this.signInWithEmail(
                      email,
                      (password: string) => {
                      
                             		  console.log("email "+email);
                             		   console.log("password "+password);
                             		   console.log("apiKey "+this.apiKey());
                        this.updateError(null);
                          const apiEndpoint = "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key="+this.apiKey();
                              const request = {  
				method: "POST",
				headers: {         
				  "Content-Type": "application/json",
				},
				body: JSON.stringify({
				  email: email,
				  password: password,
				  returnSecureToken:true
				})
			      };
                         fetch(apiEndpoint, request)
       		 .then((res) => res.json())
       		  .then((data: any) => {
       		  console.log(data);
       		  if(data.hasOwnProperty('error')){
		                  var response = {"code": null, "message": "Invalid login credentials.", "retry": null};
		                  this.updateError(response);
			  }
			  else
			  {
			          if (this.parseJwt(data) === true)
	       		  {       		           		    
		    	 		auth.signInWithEmailAndPassword(email, password)
					  .then((userCredential: any) => {
				   
					    resolve(userCredential);
					  })
					  .catch((error: any) => {
					    this.updateError(error);
					  });
	       		  }
		                  else
		                  {
		                  var response = {"code": null, "message": "User doesn't have sufficient privileges.", "retry": null};
		                  this.updateError(response);
		                  }
			  }
       		 


                          })        		 
        		 .catch((error: any) => {
                            this.updateError(error);
                          });;

                        return false;
                      });
                }
                else
                {	
                    var response = {"code": this.routeLink(), "message": "Email doesn't exist.", "retry": true};
                    this.updateError(response);
                }                
              })
              .catch((error: any) => {
                this.updateError(error);
              });
            return false;
          });
    });
  }
 
  public completeSignOut(): Promise<void> {
    return Promise.resolve();
  }

  private updateError(error: {code?: string, message?: string, retry?: any} | null) {
    const modifiedState: AppState = {
      alertParams: {
        code: (error && error.code) || undefined,
        message: (error && error.message) || undefined,
        retry: (error && error.retry) || undefined,
      },
      // Keep existing values for the rest of the state.
      mode: this.state.mode,
      navbar: this.state.navbar,
      signIn: this.state.signIn,
      signInWithEmail: this.state.signInWithEmail,
    };
    this.setState(modifiedState);
  }
  private signIn(
      saml: boolean,
      onSignInWithEmail: (email: string) => boolean) {
    this.setState({
      mode: 'SIGN_IN',
      signIn: {
        saml,
        onSignInWithEmail,
      },
      navbar: {
        link: this.state.navbar.link,
        originalUrl: this.state.navbar.originalUrl,
      },
    });
  }

  private signInWithEmail(
      email: string,
      onSignInWithEmailAndPassword: (password: string) => boolean) {
    this.setState({
      mode: 'SIGN_IN_WITH_EMAIL',
      signInWithEmail: {
        email,
        onSignInWithEmailAndPassword,
      },
      navbar: {
        link: this.state.navbar.link,
        originalUrl: this.state.navbar.originalUrl,
      },
    });
  }

 private  routeLink() {
	var link="";
	var text= window.location.href;
	if (text.startsWith("http://local"))
        {
        	link="https://dev-0.monibag.com/devsignup";
        }
        if (text.startsWith("https://dev-0-login."))
        {
        	link="https://dev-0.monibag.com/devsignup";
        }
        if (text.startsWith("https://dev-login."))
        {
			link="https://dev.monibag.com/devsignup";	                
        }
        if (text.startsWith("https://uat-login."))
        {
			link="https://uat.monibag.com/devsignup";	                
        }
        if (text.startsWith("https://login."))
        {
			link="https://monibag.com/devsignup";	                
        }
        return link;
 }
  private  apiKey() {
	var key="";
	var text= window.location.href;
	if (text.startsWith("http://local"))
        {
        	key="AIzaSyA0_idWxxmuKZy2_uBYF4_jvofF-6bLWYQ";
        }
        if (text.startsWith("https://dev-0-login."))
        {
        	key="AIzaSyC0zpRpNNNrItAwrzyGuVhb9Lj2oKcHCXQ";
        }
        if (text.startsWith("https://dev-login."))
        {
			key="AIzaSyAX7hCMhB7XQcz7NubNbKsXTuDZTtwStWM";	                
        }
        if (text.startsWith("https://uat-login."))
        {
			key="AIzaSyBcIpn0yUVVwYYBZGozERPMvpyIbmL-Btg";	                   
        }      
        if (text.startsWith("https://login."))
        {
			key="AIzaSyC8mzaxbHggnH4VODbgus43AAkIABoqlPs";	   	                
        }        		
        return key;
 }
 
 private  parseJwt(token: any) {
    try {
        var jwtKey=token.idToken;
  	var base64Payload = jwtKey.split('.')[1];
  	var payload = Buffer.from(base64Payload, 'base64');
	var dval=JSON.parse(payload.toString());
	if(Object.prototype.hasOwnProperty.call(dval, "custom_claims")) 
	{ 
	   var claims=dval['custom_claims'];
	   for(let i = 0; i < claims.length; i++)
	   {
	    
      if(claims[i].startsWith('AGENT')){
	       return true;
	    }
  	  
      if(claims[i].startsWith('ADMIN')){
	      return true;
	    }

	    if(claims[i].startsWith('SUPPORT')){
	      return true;
	    } 
	    
      if(claims[i].startsWith('BANK')){
	      return true;
	    }
      
      if(claims[i].startsWith('COMPLIANCE')){
	      return true;
	    }
	  }
	}
     } 
     catch (error) {
	return null;
     }

  return false
};

  private renderCiapComponent = (): JSX.Element => {
    switch (this.state.mode) {
      case 'SIGN_IN':
        const signIn = this.state.signIn as SignInParameters;
        return <SignIn
          saml={signIn.saml}
          onSignInWithEmail={signIn.onSignInWithEmail}
        />;
      case 'SIGN_IN_WITH_EMAIL':
        const signInWithEmail = this.state.signInWithEmail as SignInWithEmailParameters;
        return <SignInWithEmail
          email={signInWithEmail.email}
          onSignInWithEmailAndPassword={signInWithEmail.onSignInWithEmailAndPassword}
        />;
 
      default:
        return <div></div>;
    }
  }
}

export default App;

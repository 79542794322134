import React from 'react';

export interface SignInParameters {
  saml: boolean;
  onSignInWithEmail: (email: string) => boolean;
}

export class SignIn extends React.Component<SignInParameters, {email: string}> {
  constructor(props: SignInParameters) {
    super(props);
    this.state = {
      email: '',
    };
  }

  handleChange = (event: any) => {
    this.setState({
      email: event.target.value,
    });
  };

  render(): JSX.Element {
    return (
      <div className="card">
        <div className="card-header sign-in-header">MONIBAG LOGIN</div>
        <div className="card-body">
          <h5 className="card-title">Sign in</h5>
          <form id="enter-email-form"
              onSubmit={(e) => {
                this.props.onSignInWithEmail(this.state.email);
                e.preventDefault();
              }}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input className="form-control"
                  type="text"
                  placeholder="Email"
                  name="email"
                  onChange={this.handleChange}
                  value={this.state.email}
                  id="email" />
            </div>
            <button
                type="submit"
                className="btn btn-primary mb-2 search-email">
              Next
            </button>

          </form>
        </div>
      </div>
    );
  }
}

import React from 'react';

export interface NavbarParameters {
  link?: string;
  originalUrl?: string;
}

export class Navbar extends React.Component<NavbarParameters, {}> {
  render(): JSX.Element {
    return (
      <React.Fragment>
      
      </React.Fragment>
    );
  }
}

import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import App from './app';
const AppRouter: React.FC = () => {
  return (
    <Router>
      <div>
        <nav></nav>      
        <Route path="/" exact component={App}></Route>
      </div>
    </Router>
  );
}

export default AppRouter;

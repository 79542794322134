import React from 'react';

export interface AlertParameters {
  message?: string;
  code?: string;
  retry?: () => void;
}

export class Alert extends React.Component<AlertParameters, {}> {
  render(): JSX.Element {
    return (
      <React.Fragment>
        {this.props.message &&
          <div className="alert alert-danger alert-dismissible fade show" role="alert">
            
            <strong>{this.props.message}</strong> 
            {!!this.props.retry &&
		  <a href={this.props.code} className="me-5 pb-5  float-right stretched-link" title="Sign Up" >Sign Up</a>
            }
          </div>
        }
      </React.Fragment>
    );
  }
}
